// eslint-disable-next-line no-redeclare,no-unused-vars
function buildScan(callback) {
  var popup = buildPopup({
    title: 'Scan',
    noPadding: true,
    afterClose: function () {
      window.scanner.stop().then(() => {
        delete window.scanner;
      });
    },
    callback: function () {
      popup.addClass('modal-dialog-no-scrollable');
      popup.main.addClass('h-100');
      popup.body.addClass(
        'text-center d-flex align-items-center justify-content-center'
      );
      popup.body.css('min-height', 'calc(100vh - 200px)');
      var width = popup.body.width();
      var height = popup.body.height();
      var size = width < height ? width : height;
      $('<div id="scan" class="d-inline-block"></div>')
        .appendTo(popup.body)
        .width(size)
        .height(size);

      var li = buildFormLine(
        popup.body,
        {
          classLine: 'p-3',
          noMouseout: true,
          settingsNew: {
            label: 'Code',
            save: function (code) {
              if (!code) return;
              popup.close();
              callback(code);
            },
            field: ' input',
            field2: 'button',
            func: function () {
              li.element.save(li.element.val());
            },
            iconBtn: 'save',
          },
        },
        'code'
      );

      if (window.scanner)
        window.scanner.stop().then(() => {
          delete window.scanner;
        });

      window.scanner = new Html5Qrcode('scan');
      scanner.start(
        {facingMode: 'environment'},
        {fps: 10, aspectRatio: 1.0, qrbox: size / 1.5},
        function (code, result) {
          li.element.val(code);
          console.log(result);
          popup.close();
          callback(code);
        },
        function (error) {
          console.log(error);
        }
      );
    },
  });
}
